
import { action, observable, computed } from 'mobx'
import {
  setAccessToken,
  userLogin,
  userMe,
  userFind,
  userFindById,
  userCreateComment,
  deviceFind
} from '../Agent'
import { Patient } from './models'
import { isNil, intersectionWith, differenceWith, find } from "lodash";
import mqtt from '../utils/mqtt'
import { gateway, session, robot } from '.'
import moment from 'moment'

class Patients {
 
  @observable
  current

  @observable
  list = []

  @observable
  msgComment

  @observable
  loadingComment = false

  @observable
  dateComment = moment().format('YYYY-MM-DDTHH:mm')

  @observable
  disabled = true

  @observable
  robotLowBat =false

  @observable
  robotLowBatUser 

  @observable
  devicePanic =false

  @observable
  deviceUserPanic

  @observable
  devicePanicCause

  constructor(){
    mqtt.on("state", this.onRobotState.bind(this))
    mqtt.on("device",this.onDeviceState.bind(this))
  }

  async init(){   
    const { data } = await userFind({})
    const filtred = data.filter(user => user.roles.filter(role => role.name == "patient").length > 0).map((item)=>new Patient(item))
    this.list.replace(filtred)
  }

  async select(id) {

    const user = find(this.list, (p)=> p.id == id)
    if(user){
      this.current = user
    }else{
      try {
        const {data} = await userFindById({id});
        this.current = new Patient(data); 
       } catch (error) {
         console.log(error);
       }
    }

  }

  // onNetChange(connectionInfo: any) {
  //   this.online = connectionInfo.type !== "none";
  //   console.log("**** onNetChange *************************");

  //   if (this.online == true) {
  //     session.reconnect();
  //   } else {
  //     session.logout(false);
  //   }
  // }

  @action
  onPresence(newList){
    intersectionWith(this.list, newList, (p, id)=>p.id === id).map((p)=> p.setOnline(true))
    differenceWith(this.list, newList, (p, id)=>p.id === id).map((p)=> p.setOnline(false))
  }

  @action
  closeRobotLowBat(){
    this.robotLowBat = false;
    this.robotLowBatUser = null;
 
  }
  @action
  onRobotState({userId, payload}) {
    const user = find(this.list, (p)=> p.id == userId)
    
    if(user){
      
      user.robotSupport = payload.support;
      user.robotState = payload.gateway;
      user.robotBattery = payload.robot.battery.status;
      user.robotIsCharging = payload.robot.isCharging;

      if(!payload.robot.isCharging && payload.gateway === "standby" ){
        user.isWarning = true;
      }
      else{
        user.isWarning = false;
      }
      let time = new Date().getTime();
      if(payload.robot.battery.status == "low" && !this.robotLowBat && !gateway.inCall && !robot.robotStream && !payload.robot.isCharging){
        if(time-user.robotBatteryLastWarning > 60*1000*3 || !user.robotBatteryLastWarning ){
       
          this.robotLowBat = true;
          this.robotLowBatUser = user;
          user.robotBatteryLastWarning = time;
        };
      }
    }
  }

@action
  closeDevicePanic(){
    this.devicePanic = false;
    this.deviceUserPanic = null;
 
  }
  @action
  async onDeviceState({deviceId,type,payload}){
    const {data} = await deviceFind({})

    var device = data.find(d=>d.id == deviceId)
    if(!device){
      console.warn("Unknown device : " + deviceId);
      return
    }
    const user = find(this.list, (p)=> p.id == device.userId)
    
    if(user){

        switch(type){
          case "hello":
            console.log("got hello from " + deviceId);
            break;
          case "panic":
            let time = new Date().getTime();

            if(!this.devicePanic && !this.robotLowBat && !gateway.inCall && !robot.robotStream){
              if(time-user.deviceLastPanic > 60*1000*3 || !user.deviceLastPanic ){
             
                this.devicePanic = true;
                this.deviceUserPanic = user;
                this.devicePanicCause = payload;
                user.deviceLastPanic = time;
              };
            }
            console.log("got Panic from " + deviceId + " payload : "+ payload);
            break;
          case "log":
            console.log("got log from " + deviceId + " payload : " + payload);
            break;
        }



      }
  }

  @action
  doCall(){
    gateway.call(this.current.id)
  }

  @action
  setCommentDate(date) {
    this.dateComment = date 
    this.setDisabled()
  }

  setDisabled() {
    if(this.msgComment && this.dateComment) {
      this.disabled = false
    } else {
      this.disabled = true
    }
  }
  
  @action
  setCommentMsg(msg) {
    this.msgComment = msg
    this.setDisabled()
  }
  
  @action
  cancelComment() {
    this.msgComment = null
    this.dateComment = moment().format('YYYY-MM-DDTHH:mm')
  }

  @action
  async userCreateComment() {
    
    const query = {
      id: session.user.id,
      data: {
        message: this.msgComment,
        date: this.dateComment,
        patientId: this.current.id,
        operatorId: session.user.id
      }
    }
    try {
      var {data} = await userCreateComment(query)
      if(data) {
        this.loadingComment = true
        data.operator = session.user
        this.current.patientSheet.comments.push(data)
        this.current.patientSheet.comments.replace(this.current.patientSheet.comments)
        this.loadingComment = false
      }
    } catch (error) {
      console.log(error)    }
  }

}

export default Patients
