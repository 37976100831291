import React from "react";
import Video from "./Video";
import { withStyles } from "@material-ui/core/styles";
import Draggable from "react-draggable";
import { observer, inject } from "mobx-react";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import CallEndIcon from "@material-ui/icons/CallEnd";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Joystick } from "react-joystick-component";
import KeyboardEventHandler from "react-keyboard-event-handler";
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import Battery20OutlinedIcon from '@material-ui/icons/Battery20Outlined';
import Battery80OutlinedIcon from '@material-ui/icons/Battery80Outlined';
import BatteryFullOutlinedIcon from '@material-ui/icons/BatteryFullOutlined';
import BatteryUnknownOutlinedIcon from '@material-ui/icons/BatteryUnknownOutlined';
import BatteryCharging20OutlinedIcon from '@material-ui/icons/BatteryCharging20Outlined';
import BatteryCharging80OutlinedIcon from '@material-ui/icons/BatteryCharging80Outlined';
import BatteryChargingFullOutlinedIcon from '@material-ui/icons/BatteryChargingFullOutlined';
import Tooltip from "@material-ui/core/Tooltip";
import Gamepad from 'react-gamepad'

import robotImg from './robot.png'
import viseur from './viseur.png'

const styles = () => ({
  videoWrapperOuter: {
    display: "table",
    position: "absolute",
    zIndex: 10000
  },
  videoWrapperInner: {
    backgroundColor: "#002553",
    boxShadow: "1px 1px 2px #000",
    borderRadius: "5px",
    height: "640px",
    width: "600px",
    overflow: "hidden"
  },
  video: {
    display: "block",
    height: 480,
    width: 640,
    backgroundColor: '#000000',
    transformOrigin: "top left",
    transform: "rotate(-90deg) translate(-100%)"
  },
  controls: {
    position: "absolute",
    bottom: 10,
    right: 10
  },
  icon: {
    color: "#fff"
  },
  root: {
    flexGrow: 1,
    paddingVertical: 40,
    position: "absolute",
    bottom: 8
  },
  cancel: {
    position: "absolute",
    top: 10,
    right: 10
  },
  verinPercent: {
    top: 70,
    right: 30,
    width: "60%",
    position: "absolute",
    transform: "rotate(-90deg)",
    transformOrigin: "right"
  },
  progressBar:{
    height: "40px"
  },
  battery:{
    top: 87,
    right: 55,
    position: "absolute",
    width: 50,
    textAlign: 'center' 
  },
  overlay:{
    position:"absolute",
    top:0,
    left:190,
    zIndex:1
  }

});

@withStyles(styles)
@inject("session", "robot")
@observer
class Robot extends React.Component {
  constructor(props){
    super(props);
    this.state = {"overlayLine":false,"overlayRobot":false};
  }
  pressedKeys = {
    up: false,
    down: false,
    left: false,
    right: false,
    "+": false,
    "-": false
  };

  gamepadPosition ={
    X: 0,
    Y: 0
  }

  gamepadInterval = null

  keyPressInterval = null;

  handleJoystick = event => {
    const { robot } = this.props;
    switch (event.direction) {
      case "FORWARD":
        robot.linearForward();
        break;
      case "BACKWARD":
        robot.linearBackward();
        break;
      case "LEFT":
        robot.angularLeft();
        break;
      case "RIGHT":
        robot.angularRight();
        break;
    }
  };

  handleKeyUpEvent = key => {
    const { robot } = this.props;
    this.pressedKeys[key] = false;
    if (this.pressedKeyList().length === 0) {
      if (this.keyPressInterval) {
        clearInterval(this.keyPressInterval);
        this.keyPressInterval = null;
      }
    }
    switch (key) {
      case "up":
      case "down":
        robot.linearStop();
        break;
      case "left":
      case "right":
        robot.angularStop();
        break;
      case "+":
      case "-":
        robot.verinStop();
        break;
    }
  };

  pressedKeyList = () => {
    const pressed = [];
    for (let [k, v] of Object.entries(this.pressedKeys)) {
      if (v) pressed.push(k);
    }
    return pressed;
  };
  handleKeyPressEvent = () => {
    const { robot } = this.props;
    const list  = this.pressedKeyList()
    list.map(k => {
      switch (k) {
        case "up":
          robot.linearForward();
          break;
        case "down":
          robot.linearBackward();
          break;
        case "left":
          robot.angularLeft();
          break;
        case "right":
          robot.angularRight();
          break;
        case "+":
          robot.verinUp();
          break;
        case "-":
          robot.verinDown();
          break;
      }
    });
  };

  handleKeyDownEvent = key => {
    const { robot } = this.props;
    this.pressedKeys[key] = true;
    if(!this.keyPressInterval){
      this.keyPressInterval = setInterval(this.handleKeyPressEvent, 300)
    }
  };

  renderBattery = ()=>{
    if(this.props.robot.robotState){
      const{battery,isCharging } = this.props.robot.robotState;
      switch(battery.status){
        case "low":
          if(isCharging){
            return <BatteryCharging20OutlinedIcon fontSize="large" style={{"color":"red"}}/>
          }
          return <Battery20OutlinedIcon fontSize="large" style={{"color":"red"}}/>
          break
        case "normal":
          if(isCharging){
              return <BatteryCharging80OutlinedIcon fontSize="large" style={{"color":"orange"}}/>
            }
          return <Battery80OutlinedIcon fontSize="large" style={{"color":"orange"}}/>
          break
        case "high":
          if(isCharging){
            return <BatteryChargingFullOutlinedIcon fontSize="large" style={{"color":"green"}}/>
          }
          return <BatteryFullOutlinedIcon fontSize="large" style={{"color":"green"}}/>
          break
        default:
          return <BatteryUnknownOutlinedIcon fontSize="large" style={{"color":"darkgray"}}/>
          break
      }
    }else {
      return <BatteryUnknownOutlinedIcon fontSize="large" style={{"color":"darkgray"}}/>
    }
  }

  onBlur = (ev) => {
    const { robot } = this.props;
    robot.verinStop();
    robot.angularStop();
    robot.linearStop();
    
    this.pressedKeys = {
    up: false,
    down: false,
    left: false,
    right: false,
    "+": false,
    "-": false
    };
    if (this.keyPressInterval) {
        clearInterval(this.keyPressInterval);
        this.keyPressInterval = null;
    }

  }
  connectHandler(gamepadIndex) {
    console.log(`Gamepad ${gamepadIndex} connected !`)
  }
 
  disconnectHandler= (gamepadIndex)=> {
    console.log(`Gamepad ${gamepadIndex} disconnected !`)
    this.gamepadPosition ={
      X: 0,
      Y: 0
    }
    
    if (this.gamepadInterval) {
        clearInterval(this.gamepadInterval);
        this.gamepadInterval = null;
        this.gamepadEvent();
    }
  }
 
  buttonChangeHandler = (buttonName, down) => {
    console.log(buttonName)
    /*if(buttonName == "LeftTrigger" || buttonName == "LT" || buttonName == "B"){
      if(down){
        this.handleKeyDownEvent('+');
      }else{
        this.handleKeyUpEvent('+');
      }
    }
    if(buttonName == "RightTrigger" || buttonName == "RT" || buttonName == "A"){
      if(down){
        this.handleKeyDownEvent('-');
      }else{
        this.handleKeyUpEvent('-');
      }
    }*/
    switch(buttonName){
      case "LeftTrigger":
      case "LT":
      case "B":
        if(down){
          this.handleKeyDownEvent('+');
        }else{
          this.handleKeyUpEvent('+');
        }
        break;
      case "RightTrigger":
      case "RT":
      case "A":
        if(down){
          this.handleKeyDownEvent('-');
        }else{
          this.handleKeyUpEvent('-');
        }
        break;
      case "X":
        if(down){
          this.props.robot.toggleLed(!this.props.robot.robotState.led);
        }
        
        break;
      case "Y":
        if(down){
          this.setState({'overlayLine': !this.state.overlayLine})
        }
        break;
      case "LB":
        if(down){
          this.setState({'overlayRobot': !this.state.overlayRobot})
        }
        break;

    }
  }
  gamepadEvent = () =>{
    const { robot } = this.props;
      robot.angular(this.gamepadPosition["X"])
      robot.linear(this.gamepadPosition["Y"])
    
  }
  axisChangeHandler = (axisName, value, previousValue) => {
    const { robot } = this.props;
    if(axisName == "LeftStickY"){
      
      this.gamepadPosition["Y"] = value;
    }
    if(axisName == "LeftStickX"){
      this.gamepadPosition["X"] = value;
    }
    if(value == 0 && this.gamepadPosition["Y"]==0 && this.gamepadPosition["X"]==0){
      if (this.gamepadInterval) {
        console.log("stop");
        clearInterval(this.gamepadInterval);
        this.gamepadInterval = null;
      }
    }else if(!this.gamepadInterval){
      this.gamepadInterval = setInterval(this.gamepadEvent, 300)
    }
  }
 
  buttonDownHandler(buttonName) {
    console.log(buttonName, 'down')
  }
 
  buttonUpHandler(buttonName) {
    console.log(buttonName, 'up')
  }

  componentDidMount() {
     window.addEventListener("blur", this.onBlur)
  }
  componentWilUnmount() {
     window.removeEventListener("blur", this.onBlur)
  }
  render() {
    const { classes, robot } = this.props;
    return (

      <React.Fragment>
        {robot.robotStream ? (
          <Gamepad
        onConnect={this.connectHandler}
        onDisconnect={this.disconnectHandler}
 
        onButtonChange={this.buttonChangeHandler}
        onAxisChange={this.axisChangeHandler}
              >
          <Draggable
            axis="both"
            defaultPosition={{ x: 100, y: 100 }}
            bounds="parent"
            handle=".handler"
            scale={1}

          >
          
            <div className={classes.videoWrapperOuter} >
            
              <KeyboardEventHandler
                handleKeys={["up", "down", "left", "right", "+", "-"]}
                handleEventType="keyup"
                onKeyEvent={this.handleKeyUpEvent}
              />
              <KeyboardEventHandler 
                handleKeys={["up", "down", "left", "right", "+", "-"]}
                handleEventType="keydown"
                onKeyEvent={this.handleKeyDownEvent}
              />
              
              <div className={classes.videoWrapperInner}>
              {this.state.overlayLine?(<img class={classes.overlay} src={viseur} alt="viseur"/>):null}
              {this.state.overlayRobot?(<img class={classes.overlay} src={robotImg} alt="robot"/>):null}

                <div className="handler">
                  <Video
                    className={classes.video}
                    source={robot.robotStream}
                    autoPlay
                  />
                </div>
                <div className={classes.controls}>
                  <Joystick
                    size={100}
                    baseColor="#ffffff"
                    stickColor="#000000"
                    move={this.handleJoystick}
                  />
                </div>
                <div className={classes.battery}>
                  {this.renderBattery()}
                  <Tooltip title="Led">
                  {robot.robotState ?(

                   <Switch
                  
                  checked={robot.robotState.led}
                  onChange={(event)=>{robot.toggleLed(event.target.checked)}}
                  value="led"
                  color="primary"
                  inputProps={{ 'aria-label': 'Led Switch' }}
                  />):(<Switch
                  
                  checked={false}
                  value="led"
                  color="primary"
                  inputProps={{ 'aria-label': 'Led Switch' }}
                  />)}
                  </Tooltip>
                  <Tooltip title="Overlay">
                    <Switch
                    checked={this.state.overlayLine}
                    onChange={(event)=>{this.setState({"overlayLine": event.target.checked})}}
                    alue="led"
                  color="primary"
                  inputProps={{ 'aria-label': 'overlay' }}
                    />
                  </Tooltip>
                  <Tooltip title="Overlay">
                    <Switch
                      checked={this.state.overlayRobot}
                      onChange={(event)=>{this.setState({"overlayRobot": event.target.checked})}}
                      alue="led"
                    color="primary"
                    inputProps={{ 'aria-label': 'overlay' }}
                      />
                  </Tooltip>
                </div>
                <div className={classes.verinPercent}>
                  <Tooltip title="Niveau Vérin">
                  {robot.robotState ?(
                    <LinearProgress className={classes.progressBar} variant="determinate" value={robot.robotState.verinStep} />
                  ):(
                    <LinearProgress className={classes.progressBar} variant="determinate" value={0} />
                  )}</Tooltip>
                </div>
                <IconButton
                  color="inherit"
                  onClick={() => robot.closeRobotFeed()}
                  className={classes.cancel}
                >
                  <ClearIcon className={classes.icon} />
                </IconButton>
              </div>
            </div>
          </Draggable>
          </Gamepad>
        ) : null}

      </React.Fragment>
    );
  }
}

export default Robot;
